<template>
    <div class="terms">
      <h1>Terms of Service</h1>
      <p>Please read our terms of service carefully.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Terms'
  }
  </script>
  
  <style scoped>
  .terms {
    padding: 20px;
  }
  </style>